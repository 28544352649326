<template>
  <div>
    <van-sticky :offset-top="0">
      <demo-block :title="('')">
        <van-nav-bar :title="('用户个人信息')" :left-text="('返回')" left-arrow @click-left="this.$router.go(-1)">
          <template #right>
            <van-icon name="search" size="18" />
          </template>
        </van-nav-bar>
      </demo-block>
    </van-sticky>
    <van-form @submit="onSubmit" style="margin-top: 9px;">
        <van-cell-group inset>
          <van-field
            v-model="username"
            name="用户名"
            label="用户名"
            placeholder="用户名"
            :rules="[{ required: true, message: '请填写用户名' }]"
          />
          <van-field
            v-model="userArea"
            is-link
            readonly
            name="area"
            label="地区选择"
            placeholder="点击选择省市区"
            @click="showArea = true"
          />
          <van-field
            v-model="userbirthday"
            is-link
            readonly
            name="calendar"
            label="日历"
            placeholder="点击选择日期"
            @click="showCalendar = true"
          />
          <van-field name="radio" label="性别">
            <template #input>
              <van-radio-group v-model="userSex" direction="horizontal">
                <van-radio name="1">男</van-radio>
                <van-radio name="2">女</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field
            v-model="userAddress"
            is-link
            readonly
            name="address"
            label="收货地址"
            placeholder=""
            to="address"
          />
          <van-calendar v-model:show="showCalendar" @confirm="OnSelectBirthday" />
      </van-cell-group>
      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
    <van-popup v-model:show="showArea" position="bottom">
      <van-area
        :area-list="areaList"
        @confirm="onConfirm"
        @cancel="showArea = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { Row, Col, Icon, Cell, CellGroup, Form, Field, Button, Popup, Sticky, NavBar, Area, Toast, Calendar, Radio, RadioGroup } from 'vant';
import { areaList } from '@vant/area-data';

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Sticky.name]: Sticky,
    [NavBar.name]: NavBar,
    [Area.name]: Area,
    [Calendar.name]: Calendar,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio
  },
  data() {
    return {
      active: 0,
      showArea: false,
      showCalendar: false,
      userSex: '',
      userAddress: '',
      showSex: false,
      userArea: '',
      userbirthday: '',
      areaList: areaList,
      username: ''
    }
  },
  watch: {},
  computed: {},
  mounted() {
    console.log('mount')
    this.username = this.$store.state.loginuser
    this.userArea = this.$store.state.userArea
    this.userbirthday = this.$store.state.userbirthday
    this.userSex = this.$store.state.userSex
    this.userAddress = this.$store.state.userAddress
  },
  methods: {
    onSubmit () {
      this.$store.state.username = this.username
      this.$store.state.userArea = this.userArea
      this.$store.state.userbirthday = this.userbirthday
      this.$store.state.userSex = this.userSex
      this.$store.state.userAddress = this.userAddress
      Toast('修改成功')
    },
    OnSelectBirthday (day) {
      console.log(day)
      this.showCalendar = false
      this.userbirthday = `${day.getFullYear()}年${day.getMonth() + 1}月${day.getDate()}日`;
      this.$store.state.userbirthday = this.userbirthday
    },
    onConfirm (areaValues) {
      console.log(areaValues)
      this.showArea = false;
      this.userArea = areaValues
        .filter((item) => !!item)
        .map((item) => item.name)
        .join('/');
      console.log(this.userArea)
      this.$store.state.userArea = this.userArea
    }
  }
};
</script>

<style lang="less">
.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}
</style>
